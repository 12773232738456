.App {
    text-align: center;
}

ul {
    list-style-type: none;
}

li.heading {
    font-weight: bold;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    background-image: url("./images/bg_image.jpg");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-blend-mode: soft-light;
    min-height: 100vh;
}

.header {
    position: fixed;
    left: 0;
    top: 0;
    height: 50px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    text-align: center;
    color: #B88E6B;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.nameImage {
    width: 60vw;
}

.content {
    display: block;
}

.about {
    position: fixed;
    z-index: 101;
    padding: 25px;
    color: #B88E6B;
    background-color: rgba(0, 0, 0, 0.6);
    top: 50px;
    bottom: 50px;
    left: 0;
    right: 0;
    font-family: Lora, serif;
    overflow: scroll;
    display: none;

}

h1 {
    font-weight: lighter;
}

.active {
    color: gray;
}

.iconImage {
    position: fixed;
    left: 0;
    bottom: 60px;
    height: 100px;
    width: 100%;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 50px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    text-align: center;
    color: #B88E6B;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: small;
    font-family: Lora, serif;
    font-weight: lighter;
    letter-spacing: 3px;
}

.navButton {
    font-family: Lora, serif;
    margin-left: 20px;
    font-weight: bold;
    color: gray;
}

.navButtonActive {
    font-family: Lora, serif;
    margin-left: 20px;
    font-weight: bold;
}


.navButton:hover {
    cursor: pointer;
}

.copy {
    max-width: 60vw;
}

.navImage {
    height: 25px;
}

.navImage:hover {
    mix-blend-mode: color-dodge;
    cursor: pointer;
}

p {
    font-family: Lora, serif;
    color: darkgray;
    font-weight: normal;
    font-size: calc(10px + 1vmin);
}

.custom-ot-wrapper {
    transition: 0.4s box-shadow ease-in-out;
}

.custom-ot-wrapper:hover,
.custom-ot-wrapper:focus,
.custom-ot-wrapper:target {
    box-shadow: -10px 10px rgba(218, 55, 67, 0.8);
}

.switch-button {
    cursor: pointer;
    padding: 0.5rem;
    background-color: rgba(218, 55, 67, 0.6);
    align-self: center;
    color: white;
    margin-bottom: 0.5rem;
    transition: 0.4s background-color ease-in-out;
    font-size: small;
}

.switch-button:hover {
    background-color: rgb(218, 55, 67);
}

.reservations {
    position: fixed;
    left: 150px;
    top: 50px;
    width: 192px;
    z-index: 10110;
    background-color: lightgray;
}

.reservationsContainer {
    position: fixed;
    left: 10px;
    top: 60px;
    width: 192px;
    z-index: 10110;
}
.shutDown {
    color: darkred;
    font-family: Lora, serif;
    font-weight: bold;
    border: solid 1px silver;
    background-color: rgba(0,0, 0, 0.5);
    font-size: x-large;
    padding: 20px;
    max-width: 60vw;
    margin-top: -50px;
}