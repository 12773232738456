.Menu {
    position: fixed;
    z-index: 101;
    padding: 25px;
    color: #B88E6B;
    background-color: rgba(0,0,0,0.6);
    top: 50px;
    bottom: 50px;
    left: 0;
    right: 0;
    font-family: Lora, serif;
    overflow: scroll;
    display: none;
}

.pItalic {
    font-style: italic;
    font-size: small;

}

.menuList {
    list-style-position: inside;
    list-style-type: none;
    text-align: center;
}

.foodTable {
    width: 80vw;
    empty-cells: show;
    font-size: small;
    color: darkgray;

}

.beverageTable {
    width: 80vw;
    empty-cells: show;
    font-size: small;
    color: darkgray;
}


.menuIcon {
    margin-top: 30px;
}

.menuMenu {
    display: inline-flex;
}

.menuContainer {

}

.menuNav {
    margin: 20px;
    color: gray;
}

.menuNavActive {
    margin: 20px;
    text-decoration: underline;
}

.menuNav:hover {
    cursor: pointer;
}

.menuNavActive:hover {
    cursor: pointer;
}